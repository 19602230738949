import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './ReactPhoneInput.css';

import ArrowIcon from '../../assets/images/arrow-icon.svg';

type Props = {
  value?: string,
  placeholder?: string,
  name?: string,
  type?: string,
  autoFocus?: boolean,
  readOnly?: boolean,
  onChange?: (phoneNumber: string) => void,
  disabled?: boolean,
  className?: string,
  hasError?: boolean,
};

export default function ReactPhoneInput(props: Props) {
  const {
    value,
    placeholder,
    name,
    type,
    autoFocus = false,
    readOnly = false,
    onChange,
    disabled,
    className,
    hasError = false,
  } = props;

  const inputProps = {
    value,
    placeholder,
    inputProps: {
      name,
      type,
      autoFocus,
      readOnly,
    },
    onChange,
    disabled,
    country: 'us',
    className: classnames('react-phone-input', { className, hasError }),
    searchPlaceholder: 'Search...',
    enableSearch: true,
    disableSearchIcon: true,
    defaultMask: '...............',
    alwaysDefaultMask: true,
    countryCodeEditable: false,
  };

  useEffect(() => {
    const flagDropdown: any = document.querySelector('.react-phone-input .flag-dropdown');
    const flag: any = document.querySelector('.react-phone-input .flag');

    const onChangeIcon = (type: string) =>
      (flag.innerHTML = ReactDOMServer.renderToString(<img src={ArrowIcon} alt="" />));

    const observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          onChangeIcon(flagDropdown.classList.contains('open') ? 'up' : 'down');
        }
      });
    });

    const config = { attributes: true, attributeFilter: ['class'] };
    observer.observe(flagDropdown, config);
    onChangeIcon('down');
  }, []);

  return <PhoneInput
    {...inputProps}
  />
}