import React from 'react';
import { Modal, Button } from 'antd';
import { getRedirectUrl } from '../src/utils';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  show: boolean;
}

interface State {}

class RecoverAccountSuccessModal extends React.Component<Props,State> {

  render() {
    const redirectUrl = getRedirectUrl();
    const { t } = this.props;

    return (
      <>
        <Modal 
          title={t("E0B529FC-03F3-4179-8989-02FA4A2CF6B1","Success")}
          closable={false}
          centered
          visible={this.props.show}
          open={this.props.show}
          footer={
            [
              <Button
                key="submit"
                type="primary"
                href={redirectUrl}
              >
                <Trans i18nKey="E7E855FA-4602-4BE1-8097-28F613695D34">Login to Ripples Cloud</Trans>
              </Button>
            ]
          }>
          <p><Trans i18nKey="E45F8BC0-7AAA-43FA-818C-244A3069FC5F">Your account has been created, you can now log in to Ripples Cloud using your email address and selected password</Trans></p>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(RecoverAccountSuccessModal);