import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';


const i18nInit = () => {
    return i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            projectId: "0e4ba6e0-a896-4f7d-ae3c-0414be1b9350",            
        },    
        ns: 'identity', 
        defaultNS: 'identity',   
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: ['querystring', 'navigator'],
            caches: [],
        }     
    });
}

export default i18nInit;

