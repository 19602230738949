import React from 'react';
import { Modal, Button } from 'antd';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';


interface Props extends WithTranslation {
  show: boolean;
  close: ()=>void;
  errorMessage: string | null | JSX.Element;
}

interface State {

}

class ErrorModal extends React.Component<Props,State> {
  
  render() {
    const { t } = this.props

    return (
      <>
        <Modal 
          title={t("35cc0958-9385-42a8-b765-20b8be0b0073","Error")}
          visible={this.props.show} 
          onCancel={this.props.close}
          centered
          footer={[
            <Button type="primary" key="back" onClick={this.props.close}>
              <Trans i18nKey="B4BE676A-005D-4D5D-A869-87413F34DC27">OK</Trans>
            </Button>,                        
          ]}>
          <p>{this.props.errorMessage}</p>
          
        </Modal>
      </>
    );
  }
};

export default withTranslation()(ErrorModal);