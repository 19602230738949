import React from 'react';
import { Modal, Button } from 'antd';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  show: boolean;
  close: ()=>void;
  errorMessage: string | null;
}

interface State {

}

class ContactSupportModal extends React.Component<Props,State> {

  render() {
    const { t } = this.props;

    return (
      <>
        <Modal 
          title={t("A9219436-0A21-43D4-B531-9D3CBF261B95","Trouble Recovering User")}
          visible={this.props.show} 
          onCancel={this.props.close}
          centered
          footer={[
            <Button type="primary" key="back" onClick={this.props.close}>
              <Trans i18nKey="B4BE676A-005D-4D5D-A869-87413F34DC27">OK</Trans>
            </Button>,                        
          ]}>
          {this.props.errorMessage &&
            <p>{this.props.errorMessage}</p>
          }
          <p><Trans i18nKey="C4F2794A-3459-487F-9C0D-9CE638790C16">Please contact support at</Trans> <b>support@drinkripples.com</b></p>
        </Modal>
      </>
    );
  }
};
export default withTranslation()(ContactSupportModal)