import { Component } from 'react';
import { Col, Row } from 'antd';


interface Props {
    title: string;
    image?: string;
}

interface State {
   
}


class ErrorPage extends Component<Props,State> {    

    render() {   
        let imageColClasses = 'image-col signup';

        if (this.props.image) {
            imageColClasses = `image-col ${this.props.image}`;
        }

        return (
            <Row className='error-page'>
                <Col span={14}>
                    <div className="header">
                        <h2>{this.props.title}</h2>                                            
                    </div> 
                    <div className='page-content'>
                        {this.props.children}
                    </div>
                </Col>
                <Col span={10} className={imageColClasses}></Col>
            </Row>
        );
    }
}

export default ErrorPage