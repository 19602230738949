import axios, { AxiosResponse } from "axios";
import { PasswordPolicy } from "../models";

interface IdentityApiResponse<type> {
    data: type | null;
    error?: {
        message: string;
        translationKey?: string;
    }
}

interface recoveryInfoResponse {
    organization: {
        id: string;
        code: string;
        name: string;
    };
    sent2FaNotification: boolean;
    sent2FaRipple: boolean;
}

export const recoverAccountCheck = async (serial: string): Promise<recoveryInfoResponse> => {
    let recoveryInfo = null;
    recoveryInfo = await axios.post("/api/org/recover/check", {
        "serial": serial
    });
    recoveryInfo = recoveryInfo.data.data;
    return recoveryInfo;
}

export const recoverAccount = async (recoverOrgData: any): Promise<recoveryInfoResponse> => {
    let recoveryInfo = null;
    recoveryInfo = await axios.post("/api/org/recover", recoverOrgData);
    recoveryInfo = recoveryInfo.data.data;    
    return recoveryInfo;
}

interface ResetPasswordWithTokenRequest {
    auth0state: string;
    sessionToken: string;
    password: string;
}

interface ResetPasswordWithTokenResponse {
    redirectTo: string;
}

export const resetPasswordWithToken = async (params: ResetPasswordWithTokenRequest): Promise<ResetPasswordWithTokenResponse> => {
    let recoveryInfo: AxiosResponse<IdentityApiResponse<ResetPasswordWithTokenResponse>> = await axios.post("/api/users/resetPassword/auth0", params);
    return recoveryInfo.data.data as ResetPasswordWithTokenResponse;    
}


export const getPasswordPolicy = async (database: string) : Promise<PasswordPolicy>  => {
    let recoveryInfo: AxiosResponse<IdentityApiResponse<any>> = await axios.get(`/api/database/passwordPolicy/${database}`);
    return recoveryInfo.data.data;  
}


export const userSignup = async (accountData: any) => {
    const params = {
        firstName: accountData.firstName,
        lastName: accountData.lastName,
        email: accountData.email,
        phoneNumber: accountData.phoneNumber,
        password: accountData.password,
        selectedOrgToken: accountData.selectedOrgToken,
        ...(accountData.organizationName ? { organizationName: accountData.organizationName } : null),
        ...(accountData.serial ? { serial: accountData.serial } : null),
        captcha: accountData.captcha,
    }

    return await axios.post("/api/users/signup", params);
}