import { Component } from 'react';
import * as api from '../../src/api';
import ConfirmOrgModal from '../../modal/ConfirmOrgModal';
import ContactSupportModal from '../../modal/ContactSupportModal';
import ConfirmCodeModal from '../../modal/ConfirmCodeModal';
import SuccessModal from '../../modal/RecoverAccountSuccessModal';
import SignupInner from './SignupInner';
import { OrgData } from '../../models';
import { withTranslation, WithTranslation } from 'react-i18next';
import ErrorModal from '../../modal/ErrorModal';
import { translateError } from '../../src/utils';
import queryString from 'query-string';

interface Props extends WithTranslation {
    location: {
        search: string;
    }
}

interface State {
    orgData: OrgData;
    showModal: modals | null    
    isLoading: boolean;
    formData: any;
    errorMessage: string| null;
    sent2FaRipple: boolean;
    selectedOrgToken: string;
    selectedOrgName: string;
    serialError?: string;
    serial?: string | null;
    isPartner?: boolean;
}

enum modals {
    CONFIRM_ORG,
    CONFIRM_CODE,
    SUCCESS,
    SUPPORT,
    ERROR
}

class Signup extends Component<Props,State> {    

    constructor(props: Props) {
        super(props);

        let serial: string = '';
        let isPartner: boolean = false;
        let selectedOrgToken: string = '';
        let selectedOrgName: string = '';
        const queryParams = queryString.parse(props.location.search);

        if (queryParams.redirect_uri) {
            const url = new URL(queryParams.redirect_uri as string);
            const params = new URLSearchParams(url.search);
            isPartner = params.get('isPartner') === 'true';
            serial = params.get('serial') || '';
        }

        if (typeof queryParams.org === 'string') {
            selectedOrgToken = queryParams.org;
            if (selectedOrgToken) {
                const dataPart = JSON.parse(atob(selectedOrgToken.split('.')[1]));
                selectedOrgName = dataPart.name;
            }
        }

        this.state = {
            showModal: null,
            isLoading: false,
            orgData: {"id":"","code": "", "name": "test"},
            formData: {"serial": ""},
            errorMessage: null,
            sent2FaRipple: false,
            selectedOrgToken: selectedOrgToken,
            selectedOrgName: selectedOrgName,
            ...(serial ? { serial } : null),
            isPartner
        }
    }

    recoverAccountFlow = async (values: any) => {
        const { t } = this.props;

        try {
            const orgData =  await api.recoverAccountCheck(values.serial);
            this.setState({
                orgData: orgData.organization,
                formData: values,
                showModal: modals.CONFIRM_ORG,
                sent2FaRipple: orgData.sent2FaRipple,
            });
        } catch (err: any) {
            const errorMessage = translateError(t, err);
            if (err.response && err.response.status === 404) {
                this.setState({serialError: errorMessage, isLoading: false});
            } else {
                this.setState({showModal: modals.ERROR, formData: values, errorMessage});
            }
        }
    }

    userSignup = async (values: any) => {
        const { t } = this.props;

        if (this.state.selectedOrgToken.length) {
            values.selectedOrgToken = this.state.selectedOrgToken;
        }

        if (this.state.serial) {
            values.serial = this.state.serial;
        }

        try {
            await api.userSignup(values);

            this.setState({
                showModal: modals.SUCCESS,
            });
        } catch (err: any) {
            const errorMessage = translateError(t, err);
            this.setState({ showModal: modals.ERROR, formData: values, errorMessage });
        }
    }

    onFinish = async (values: any, isRecover: boolean) => {
        this.setState({ isLoading: true, serialError: "" });

        if (isRecover) {
            await this.recoverAccountFlow(values);
        } else {
            await this.userSignup(values);
        }
       
    }

    confirmOrg = async () => {
        this.setState({showModal: modals.CONFIRM_CODE});
    }    

    resendCode = async () => {
        await api.recoverAccountCheck(this.state.formData.serial);
    }

    recoverAccount = async (confirmationCode: string) => {
        const { t } = this.props;

        const createUserData = {
            firstName: this.state.formData.firstName,
            lastName: this.state.formData.lastName,
            email: this.state.formData.email,
            phoneNumber: this.state.formData.phoneNumber,
            password: this.state.formData.password,
            recoverOrg: {
                serial: this.state.formData.serial,
                confirmationCode,
            }
        }
        try {
            await api.recoverAccount(createUserData);
            this.setState({showModal: modals.SUCCESS});
        } catch (err: any) {
            if (err.response.status === 401) {
                throw new Error('Wrong Conformation Code');
            }
            this.setState({errorMessage: translateError(t, err), showModal: modals.SUPPORT});
        }

    }

    rejectOrg = () => {
        this.setState({showModal: modals.SUPPORT})
    }

    render() {
        return (
            <>
                <ErrorModal
                    show={this.state.showModal === modals.ERROR}
                    close={() =>{this.setState({showModal: null, isLoading: false, errorMessage: null})}}
                    errorMessage={this.state.errorMessage}
                />
                <ConfirmOrgModal
                    show={this.state.showModal === modals.CONFIRM_ORG}
                    orgData={this.state.orgData}
                    rejectOrg={this.rejectOrg}
                    confirmOrg={this.confirmOrg}
                />
                <ConfirmCodeModal
                    sent2FaRipple={this.state.sent2FaRipple}
                    show={this.state.showModal === modals.CONFIRM_CODE}
                    recoverAccount={this.recoverAccount}
                    resendCode={this.resendCode}
                />
                <ContactSupportModal
                    show={this.state.showModal === modals.SUPPORT}
                    errorMessage={this.state.errorMessage}
                    close={() =>{this.setState({showModal: null, isLoading: false, errorMessage: null})}}
                />
                <SuccessModal
                    show={this.state.showModal === modals.SUCCESS}
                />
                <SignupInner
                    onFinish={this.onFinish}
                    isLoading={this.state.isLoading}
                    selectedOrgName={this.state.selectedOrgName}
                    serialError={this.state.serialError}
                    isPartner={this.state.isPartner}
                />
            </>
        );
    }
}

export default withTranslation()(Signup)