import { Component } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

interface Props extends WithTranslation {
}

interface State {
   
}

class VerifyEmailError extends Component<Props,State> {    

    render() {  
        const { t } = this.props;
      
        return (
            <ErrorPage title={t('40065e9d-65e4-47b2-b5ed-1adf8755dae5', "Pease Verify Your Email")} image="reset-password">
                <p>
                    <Trans i18nKey="dd7fdf46-59bb-47fd-958a-e61f82262136">You need to verify your email address before signing in.</Trans>                            
                </p>
                <p>
                    <Trans i18nKey="6c0288df-eff8-4d49-8b0c-41ab7d18d6a5">A verification message was resent to you. Please check your email and follow the link to verify your email address.</Trans>                            
                </p>
            </ErrorPage>
        );
    }
}

export default withTranslation()(VerifyEmailError)