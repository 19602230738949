import { Component } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import {Col, Row} from 'antd';

import './PasswordHelper.css';


interface Props extends WithTranslation {
    password: string
}

interface State {
}


class PasswordHelper extends Component<Props,State> { 

    checkMark = (isOk: boolean) => {
        if (isOk) {
            return (<span className='check ok'></span>)
        } else {
            return (<span className='check'></span>)
        }

    }
    
    render() {

        const isLengthValid = this.props.password.length >= 8;
        const hasUppercaseLetter = /[A-Z]/.test(this.props.password);
        const hasLowercaseLetter = /[a-z]/.test(this.props.password);
        const hasNumber = /[0-9]/.test(this.props.password);

        return (
            <div className='password-helper'>
                <Row gutter={10} >
                    <Col span={12}>
                        {this.checkMark(isLengthValid)}
                        <Trans i18nKey="616487ec-d99d-460d-a9e9-6d939fa55ab5">Minimum 8 Characters</Trans>
                    </Col>
                    <Col span={12}>
                        {this.checkMark(hasUppercaseLetter)}
                        <Trans i18nKey="e7b00a79-6c11-44fb-abfd-9ea51c4c524f">One Uppercase Letter</Trans>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        {this.checkMark(hasLowercaseLetter)}
                        <Trans i18nKey="05ffa384-e491-47ea-a07e-dbec353b79d0">One Lowercase Letter</Trans>
                    </Col>
                    <Col span={12}>
                        {this.checkMark(hasNumber)}
                        <Trans i18nKey="74ae7f0b-eaf7-4092-8534-55637cc37b59">One Number</Trans>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default withTranslation()(PasswordHelper)