import React from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { OrgData } from '../models'; 
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  show: boolean;
  orgData: OrgData;
  confirmOrg: ()=>void;
  rejectOrg: ()=>void;
}

interface State {
  checked: boolean;
  isLoading: boolean;
}

class ConfirmOrgModal extends React.Component<Props,State> {

  constructor(props: Props) {
    super(props);
    this.state ={
      checked: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.show !== this.props.show && !this.props.show) {
      this.setState({
        isLoading: false,
        checked: false
      });
    }
  }

  confirmOrg = () => {
    this.setState({isLoading: true})
    this.props.confirmOrg();
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Modal 
          title={t("A34ADFFE-1911-41D6-8A11-402B42943B53", "Confirm Organization")}
          visible={this.props.show} 
          closable={false}
          centered
          footer={[
            <Button key="back" onClick={this.props.rejectOrg}>
              <Trans i18nKey="87352F6F-7085-4414-B375-2125B0F5D6C9">This is not my organization</Trans>
            </Button>,
            <Button 
                key="submit" 
                type="primary" 
                onClick={this.confirmOrg}
                disabled={!this.state.checked}
                loading={this.state.isLoading}
            >
              <Trans i18nKey="11595051-62F4-4C8C-B7DF-1B22A50ACFBE">Confirm</Trans>
            </Button>,            
          ]}>
          <p><Trans i18nKey="25693378-7001-491B-9138-DBAB5A803CEA" values={{"org": this.props.orgData.name}}>The RippleMaker you entered is associated with the organization {this.props.orgData.name}</Trans></p>
          <Checkbox
            checked={this.state.checked}
            onChange={e => {this.setState({checked: e.target.checked})}}
          >
            <Trans i18nKey="A5CD61C3-00DC-4326-9514-2EE2A643368F">I agree to the</Trans> <a href="https://www.drinkripples.com/terms-and-conditions" target="_blank" rel="noreferrer" ><Trans i18nKey="A6BD726B-847A-4EEF-8618-53F3B9CD7804">Ripples Terms and Conditions</Trans></a>
          </Checkbox>
        </Modal>
      </>
    );
  }
};
export default withTranslation()(ConfirmOrgModal)