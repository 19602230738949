import { PasswordPolicy } from "../models";

const getQueryVariable = (variable: string) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] === variable) {return pair[1];}
    }
    return "";
  }

 export const getRedirectUrl = () => {
    let redirectUrl = getQueryVariable("returnTo");
    if (!redirectUrl) {
      redirectUrl = "https://rplportalstatic0.blob.core.windows.net/pages/landing.html"
    } else {
      redirectUrl = atob(redirectUrl);
    }
    return redirectUrl;
 }

/** 
* Parse JWT token for UI uses only!
* This is not secure, the whole token needs to be sent to backend to validate against secret
*/
export const parseJwt = (token: string) => {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

export const validatePassword = (password: string, policy: PasswordPolicy) => {
  if (!password) return false;
  const exp = new RegExp(`${policy.pattern}`, 'g');
  return Boolean(password.match(exp));
}


export const translateError = (t: any, error: any, falback: string = "") => {
  let errMsg;

  if (error.response && error.response.data && error.response.data.error) {
    error = error.response.data.error;
    if (error.translation) {
        errMsg = t([error.translation.code, error.message], error.translation.variables);       
    } else {
      errMsg = error.message;
    }
  } else {
    errMsg = error.message;
  }

  if (!errMsg) errMsg = falback;

  return errMsg;
}
