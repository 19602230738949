import './App.css';
import { Layout } from 'antd';
import ResetPassword from './pages/resetPassword/ResetPassword';
import RolesPage from './pages/roles/RolesPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import VerifyEmailError from './pages/errorPage/VerifyEmailError';
import Signup from './pages/register/Signup';

const { Content, Header } = Layout;


function App() {
  return (
    <Router>
      <Layout className='layout'>
        <Header>
          <div id="logo"></div>
        </Header>
        <Content>
          <Switch>
            <Route exact path="/signup" component={Signup}></Route>
            <Route exact path="/reset-password" component={ResetPassword}></Route>
            <Route exact path="/error/verify-email" component={VerifyEmailError}></Route>
            <Route exact path="/roles" component={RolesPage}></Route>
          </Switch>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
