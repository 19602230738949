import { Component } from 'react';
import * as api from '../../src/api';
import * as utils from '../../src/utils';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import queryString from 'query-string';
import ResetPasswordInner from './ResetPasswordInner';
import ErrorModal from '../../modal/ErrorModal';
import SuccessModal from '../../modal/SuccessModal';
import { PasswordPolicy } from '../../models';


const defaultPasswordPolicy: PasswordPolicy = 
{
    "pattern": "((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])|(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*#?&])|(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])).{8,}",
    "description": "Password must be at least 8 characters long and contain 3/4 of the following: - Uppercase Letters (A-Z) - Lowercase Letters - Numbers (0-9) - Special Characters (eg. !@#$%^&*) ",
    "translationKey": "0c66e9c7-8681-42bb-9579-42602cf2f0b2"
}

interface Props extends WithTranslation {
    location: {
        search: string;
    }
}

enum modals {
    ERROR,
    SUCCESS
}

interface State {
    isLoading: boolean;
    auth0state: string;
    sessionToken: string;
    userEmail: string;
    userDatabase: string;
    showModal: modals | null;  
    message: string | null | JSX.Element; 
    passwordPolicy: PasswordPolicy;
}


class ResetPassword extends Component<Props,State> {    


    constructor(props: any) {
        super(props);

        const queryParams = queryString.parse(props.location.search);         

        let tokenPayload = {email: "", database: ""};
        if (typeof queryParams.session_token === 'string')
            tokenPayload = utils.parseJwt(queryParams.session_token);


        this.state = {
            isLoading: false,
            sessionToken: queryParams.session_token as string,
            auth0state: queryParams.state as string,
            userEmail: tokenPayload.email,
            userDatabase: tokenPayload.database,
            showModal: null,
            message: null,
            passwordPolicy: defaultPasswordPolicy,
        }

       api.getPasswordPolicy(tokenPayload.database).then((passwordPolicy: PasswordPolicy) => {
        this.setState({passwordPolicy})
       });
    }

    closeModal = () => {
        this.setState({
            showModal: null, 
            isLoading: false,
            message: null,
        });
    }

    onSubmit = async (values: {password: string}) => {
        const { t } = this.props

        try {
            const pwReset = await api.resetPasswordWithToken({
                password: values.password,
                auth0state: this.state.auth0state,
                sessionToken: this.state.sessionToken
            });

            window.location.assign(pwReset.redirectTo);

            const successMessage = (
                <>
                    <p><Trans i18nKey="5608fffe-bed3-4dcb-9c33-cfce51a05160">Your password has been successfully reset.</Trans></p>
                    <p>
                        <Trans i18nKey="5feea4c5-7778-4184-b969-41a8fac8916a">
                            If you are not automatically redirected, please <a href={pwReset.redirectTo}>click here</a>.
                        </Trans>
                    </p>
                </>
            )
            this.setState({showModal: modals.SUCCESS, message: successMessage});
        } catch (err: any) {
            const errorMessage = utils.translateError(t, err);
                    
            this.setState({showModal: modals.ERROR, message: errorMessage});            
        }
    }

    render() {        
        return (
            <>
                <ErrorModal
                    show={this.state.showModal === modals.ERROR} 
                    close={this.closeModal}
                    errorMessage={this.state.message}
                />
                <SuccessModal
                    show={this.state.showModal === modals.SUCCESS} 
                    close={this.closeModal}
                    message={this.state.message}
                />
                <ResetPasswordInner 
                    userEmail={this.state.userEmail}
                    onSubmit={this.onSubmit}
                    isLoading={this.state.isLoading}
                    passwordPolicy={this.state.passwordPolicy}
                />            
            </>
        );
    }
}

export default withTranslation()(ResetPassword)