import React from 'react';
import { Modal, Button } from 'antd';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';


interface Props extends WithTranslation {
  show: boolean;
  close: ()=>void;
  message: string | null | JSX.Element;
}

interface State {

}

class SuccessModal extends React.Component<Props,State> {
  
  render() {
    const { t } = this.props

    return (
      <>
        <Modal 
          title={t("934dd4e0-bb4e-4f98-8875-dac62c813812","Success")}
          visible={this.props.show} 
          onCancel={this.props.close}
          centered
          footer={[
            <Button type="primary" key="back" onClick={this.props.close}>
              <Trans i18nKey="B4BE676A-005D-4D5D-A869-87413F34DC27">OK</Trans>
            </Button>,                        
          ]}>
          <p>{this.props.message}</p>
          
        </Modal>
      </>
    );
  }
};

export default withTranslation()(SuccessModal);