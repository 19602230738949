import { Component } from 'react';
import { getRedirectUrl } from '../../src/utils';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Form, Input, Button, Col, Row, Checkbox, Modal } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import findSerialRm1 from '../../assets/images/find-serial-rm1.png';
import findSerialRm2 from '../../assets/images/find-serial-rm2.png';
import ReCAPTCHA from "react-google-recaptcha";
import PasswordHelper from './PasswordHelper';
import ReactPhoneInput from '../../components/ReactPhoneInput/ReactPhoneInput';
import _ from 'lodash';

interface Props extends WithTranslation {
  onFinish: (values: any, isRecover: boolean)=>Promise<void>;
  isLoading: boolean;
  selectedOrgName: string;
  serialError?: string;
  isPartner?: boolean;
}

interface State {
  isRecoverFlow: boolean;
  isModalOpen: boolean;
  phoneNumberHasError: boolean;
  password: string;
  isSelectedOrg: boolean;
  captcha?: string;
}

class SignupInner extends Component<Props,State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isRecoverFlow: false,
      isModalOpen: false,
      phoneNumberHasError: false,
      password: '',
      isSelectedOrg: !!this.props.selectedOrgName.length,
    }
  }

  toggleRecoverFlow = (event: CheckboxChangeEvent) => {
    this.setState({isRecoverFlow: event.target.checked});
  }

  validatePasswordStrength = (rule: any, value: string) => {
    // this only returns true/false if field is valid. the actual UI is below

    // var reg = [/[a-z]/,/[A-Z]/,/[0-9]/,/[(!@#$%&]/]
    var reg = [/[a-z]/,/[A-Z]/,/[0-9]/]
    var count = 0;
    for (var i = 0; i < reg.length;i++) {
      if (reg[i].test(value)) {
        count++;
      }
    }

    if (count >=3 && value.length >=8) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  };

  passwordFieldUpdated = (event: any) => {
    this.setState({password: event.target.value})
  }

  normalizeSerial (value: string) {
    return (value || '').toUpperCase().replace(/[^a-z0-9]+/gi, "");
  }

  closeModal = () => {
    this.setState({isModalOpen: false});
  }

  openModal = () => {
    this.setState({isModalOpen: true});
  }

  onFinish = (formData: any) => {
    if (this.state.captcha) {
      formData.captcha = this.state.captcha
    }
    this.props.onFinish(formData, this.state.isRecoverFlow);
  }

  onCompleteCaptcha = (token: string | null) => {
    if (token) this.setState({captcha: token})
  }

  render() {
    const { t } = this.props;

    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    const tailLayout = {};

    const redirectUrl = getRedirectUrl();

    const formRowGutter = 40;

    const orgName = this.props.selectedOrgName;

    const needsCaptcha = !this.state.isRecoverFlow && !this.state.isSelectedOrg;

    const formDisabled = (needsCaptcha && !this.state.captcha);

    const phoneNumberOnChange = (phoneNumber: string) =>
      this.setState({ phoneNumberHasError: _.isEmpty(_.trim(phoneNumber)) });

    const onFinishFailed = (err: any) => {
      const { phoneNumber } = err.values;
      phoneNumberOnChange(phoneNumber);
      // console.error(err);
    }

    return (
      <>
        <Row id="signup-header">
          <Col className="page-title" span={24}>
            <h2><Trans i18nKey="b2788956-8655-4b0d-98f7-ac43b6ae75a4">Sign Up</Trans></h2>
            <span className='h-bold'><Trans i18nKey="5a807f7a-34e8-41be-8c7e-653449d96758">Already have an account?</Trans></span>
            <a className='h-action' href={redirectUrl}><Trans i18nKey="6b0efe8e-d805-4a29-89ca-b593c6615bc8">Log In</Trans></a>
          </Col>
          {this.state.isSelectedOrg && !this.props.isPartner && (
            <Col span={24}>
              <p>
                <Trans i18nKey="db70aa75-cae1-4eeb-b360-606adc548720">User account will be created for organization "{{orgName}}"</Trans>
              </p>
            </Col>
          )}
        </Row>

        <Row id="signup-form-layout" align='stretch'>
          <Col span={14}>

            <Form
              {...layout}
              name="basic"
              onFinish={this.onFinish}
              onFinishFailed={onFinishFailed}
            >

              <Row id="signup-row-1" gutter={formRowGutter}>
                <Col span={12} className='form-col'>
                  <Form.Item
                    {...tailLayout}
                    name="firstName"
                    rules={[{ required: true, message: t("40DF6A6E-068B-4B5D-BE10-9F402AD6D680", 'Please input your first name!') }]}
                  >
                    <Input placeholder={t("1B2DE967-344F-41E5-B552-38806148D354", "First Name")} />
                  </Form.Item>
                </Col>
                <Col span={12} className='form-col'>
                  <Form.Item
                    {...tailLayout}
                    name="lastName"
                    rules={[{ required: true, message: t("67F2DD96-5A5B-44B2-BCD4-D48262A1EC52", 'Please input your last name!') }]}
                  >
                    <Input placeholder={t("65A85443-A3AF-4347-A23C-4B600000C2A3", "Last Name")} />
                  </Form.Item>
                </Col>
              </Row>

              <Row id="signup-row-2" gutter={formRowGutter}>
                <Col span={12} className='form-col' style={{ zIndex: 99 }}>
                  <Form.Item
                    {...tailLayout}
                    name="phoneNumber"
                    rules={[{ required: true, message: t("f270cb5f-aef5-4653-849e-1b487703b846", "Please type your phone number") }]}
                  >
                    <ReactPhoneInput
                      name="phoneNumber"
                      hasError={this.state.phoneNumberHasError}
                      onChange={phoneNumberOnChange}
                      placeholder={t("86D07F8E-E191-467F-A144-17AAF51B0753", "Phone Number")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className='form-col'>
                  <Form.Item
                    {...tailLayout}
                    name="email"
                    rules={[{ required: true, type: "email", message: t("77AB3BB0-CCBA-4EAD-895B-3AB80DFB1156", 'The input is not valid Email!') }]}
                  >
                    <Input placeholder={t("68C001AB-6E30-4FE5-849A-95B922608200", "Email Address")} />
                  </Form.Item>
                </Col>
              </Row>

              {this.props.isPartner && (
                <Row id="signup-row-3" gutter={formRowGutter}>
                  <Col span={24} className='form-col'>
                    <Form.Item
                      {...tailLayout}
                      name="organizationName"
                      rules={[{ required: true, message: t("e6d1c00d-870c-4a3b-8de5-e204327731cd", "Please type your company name") }]}
                    >
                      <Input placeholder={t("c5c664e8-8be5-462e-b57f-fed12df7cb0c", "Company Name")} />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row id="signup-row-4" gutter={formRowGutter}>
                <Col span={12} className='form-col'>
                  <Form.Item
                    {...tailLayout}
                    name="password"
                    className='password-field'
                    rules={[
                      {
                        required: true,
                        validator: this.validatePasswordStrength,
                      }
                    ]}
                  >
                    <Input.Password
                      placeholder={t("96DF0070-A1DC-4409-8F49-8608F6EBAB4F", "Password")}
                      onChange={this.passwordFieldUpdated}
                    />
                  </Form.Item>
                  <PasswordHelper password={this.state.password}></PasswordHelper>
                </Col>
                <Col span={12} className='form-col'>
                  <Form.Item
                    {...tailLayout}
                    name="confirm"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: t("278E1232-23F5-4D68-BFEF-6C062A26075F", 'Please confirm your password!'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("927F54AB-28C6-4716-A138-4551E330FD6A", 'The two passwords that you entered do not match!')));
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder={t("185CDBB5-FCBC-4561-B662-72644C6B4E7E", "Confirm Password")} />
                  </Form.Item>
                </Col>
              </Row>

              {(!this.state.isSelectedOrg && !this.props.isPartner) && (
                <Row id="signup-row-5" gutter={formRowGutter}>
                  <Col span={24}>
                    <Checkbox
                      checked={this.state.isRecoverFlow}
                      onChange={this.toggleRecoverFlow}
                    >
                      <Trans i18nKey="ac591d0a-5cdb-458f-bea6-7c0e2340d4f3">I already have a Ripple Maker</Trans>
                    </Checkbox>

                    {this.state.isRecoverFlow && (
                      <>
                        <p className='p-margin p-bold'><Trans i18nKey="05f3f556-33b0-47ec-ac32-2f41dd533ce4">Fill in your Ripple Maker's serial number so we can connect it to your new account</Trans></p>
                        <Row>
                          <Col span={12} className='form-col'>
                            <Form.Item
                              name="serial"
                              normalize={this.normalizeSerial}
                              rules={[{ required: true, message: t("4404D0CF-D6DF-4ED3-AE1E-092650687EF8", 'Please input serial') }]}
                              style={{marginBottom: 0}}
                            >
                              <Input placeholder={t("7216F6D3-2222-42AB-81B5-DBE3229DD734", "Ripple Maker Serial Number")} />

                            </Form.Item>
                            {this.props.serialError && (
                              <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">{this.props.serialError}</div></div>
                            )}
                            <p
                              style={{
                                textDecoration: 'underline',
                                textAlign: 'right',
                                fontSize: '0.9em',
                                marginTop: '5px',
                                cursor: 'pointer'}}
                              onClick={this.openModal}
                            >
                              <Trans i18nKey="f9ef6465-0700-4c9a-8792-b0125f63d8de">Where can I find it?</Trans>
                            </p>

                            <Modal
                              visible={this.state.isModalOpen}
                              onCancel={this.closeModal}
                              footer={null}
                              width="90%"
                              centered
                              className='find-serial-modal'>
                              <div className="wrapper">
                                <h2><Trans i18nKey="06a66b3a-19ec-4d1a-b95b-f44fbd69f871">Where to find the Ripple Maker's Serial Number?</Trans></h2>
                                <Row gutter={60}>
                                  <Col span={7}>
                                    <p className="p-bold" style={{marginBottom: 0}}>Ripple Maker AM / PM</p>
                                    <p style={{fontSize: '0.9em', color: '#808080'}}><Trans i18nKey="0669d94f-2dc9-4e3b-9bf7-2aeaacd3af27">Tapping on the top left menu</Trans></p>
                                    <img className="fit-image" src={findSerialRm1} alt="" />
                                  </Col>
                                  <Col span={17}>
                                    <p className="p-bold" style={{marginBottom: 0}}>Ripple Maker II / II Pro</p>
                                    <p style={{fontSize: '0.9em', color: '#808080'}}><Trans i18nKey="06d58fc2-fe30-4bce-9922-0cfe2e13c8eb">Tapping on info icon in left menu</Trans></p>
                                    <img className="fit-image" src={findSerialRm2} alt="" />
                                  </Col>
                                </Row>
                              </div>
                            </Modal>
                          </Col>
                        </Row>
                      </>
                    )}

                  </Col>
                </Row>
              )}

              <Row id="signup-row-6" gutter={formRowGutter}>
                <Col span={12} className='form-col'>
                  {needsCaptcha && (
                    <div className='recaptcha'>
                      <ReCAPTCHA
                        sitekey='6LeFHkgmAAAAAJYl4uWTHiSZhgnsLM1o4Rq1aNZ1'
                        onChange={this.onCompleteCaptcha}
                      ></ReCAPTCHA>
                    </div>
                  )}

                  <Form.Item {...tailLayout} className="buttons">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.props.isLoading}
                      disabled={formDisabled}
                    >
                      <Trans i18nKey="fbda6b21-d7b9-4037-8ca3-a512b9ff625a">Create Account</Trans>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Col>
          <Col span={10} className='image-col signup'>

          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(SignupInner)