import { Component } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { PasswordPolicy } from '../../models';
import * as utils from '../../src/utils';

interface Props extends WithTranslation {
    onSubmit: (values: any)=>Promise<void>;
    isLoading: boolean;
    userEmail: string;
    passwordPolicy: PasswordPolicy;
}

interface State {}

class ResetPasswordInner extends Component<Props,State> {    

    validatePasswordStrength = (rule: any, value: string, callback: any) => {

        if (utils.validatePassword(value, this.props.passwordPolicy)) {
          callback();
        } else {
          callback((
            <div>
                <Trans i18nKey={this.props.passwordPolicy.translationKey}>{this.props.passwordPolicy.description}</Trans>              
            </div>
          ));
        }
    };



    render() {
        const { t } = this.props;

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };
        const tailLayout = {};
        
        const email = this.props.userEmail;

        return (
            <>
                <Row id="recover-account">
                    <Col className="page-title" span={24}>
                        <h2><Trans i18nKey="4d0fb6e8-fe85-4615-8ae3-f1065f558429">Reset Your Password</Trans></h2>                        
                    </Col>
                </Row>

                <Row id="signup-form-layout" align='stretch'>
                    <Col span={14}>
                        <p>
                            <Trans i18nKey="4c3806d9-5049-416e-94b3-01507710d2e3" values={{email}}>Please set a new password for user {{email}}</Trans>                            
                        </p>
                
                        <Form
                        {...layout}
                        name="basic"
                        onFinish={this.props.onSubmit}
                        onFinishFailed={(err) => {console.log(err)}}
                        >

                        <Form.Item
                            {...tailLayout}
                            label={t("96DF0070-A1DC-4409-8F49-8608F6EBAB4F","Password")}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    validator: this.validatePasswordStrength,
                                }
                            ]}
                        >
                            <Input.Password placeholder={t("96DF0070-A1DC-4409-8F49-8608F6EBAB4F", "Password")} />
                        </Form.Item>

                        <Form.Item
                            {...tailLayout}
                            label={t("185CDBB5-FCBC-4561-B662-72644C6B4E7E", "Confirm Password")}
                            name="confirm"
                            dependencies={['password']}
                            rules={[
                                {
                                required: true,
                                message: t("278E1232-23F5-4D68-BFEF-6C062A26075F", 'Please confirm your password!'),
                                },
                                ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t("927F54AB-28C6-4716-A138-4551E330FD6A", 'The two passwords that you entered do not match!')));
                                },
                                }),
                            ]}
                        >
                            <Input.Password placeholder={t("185CDBB5-FCBC-4561-B662-72644C6B4E7E", "Confirm Password")} />
                        </Form.Item>
                        
                        <Form.Item {...tailLayout} className="buttons">                        
                            <Button 
                                type="primary" 
                                htmlType="submit"
                                loading={this.props.isLoading}
                            >
                            <Trans i18nKey="75e178de-8326-47be-93bb-5f354a9b1107">Reset Password</Trans>
                            </Button>
                        </Form.Item>
                        </Form>
                    </Col>

                    <Col span={10} className='image-col reset-password'></Col>
                
                </Row>
            </>
        );
    }
}

export default withTranslation()(ResetPasswordInner)