import React from 'react';
import { Modal, Button, Input } from 'antd';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  show: boolean;
  recoverAccount: (confirmationCode: string)=>void;
  resendCode: ()=>void;
  sent2FaRipple: boolean;
}

interface State {
  confirmationCode: string;
  isLoading: boolean;
  isResendLoading: boolean;
  isError: boolean;
}

class ConfirmCodeModal extends React.Component<Props,State> {

  constructor(props: Props) {
    super(props);
    this.state ={
      confirmationCode: "",
      isLoading: false,
      isResendLoading: false,
      isError: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.show !== this.props.show && !this.props.show) {
      this.setState({
        isLoading: false,
        isResendLoading: false,
        confirmationCode: ""
      });
    }
  }

  recoverAccount = async () => {
    this.setState({isLoading: true, isError: false});
    try {
      await this.props.recoverAccount(this.state.confirmationCode);
    } catch (e) {
      this.setState({isError: true, isLoading: false});
    }
  }

  resendCode = async () => {
    this.setState({isResendLoading: true});
    await this.props.resendCode();
    this.setState({isResendLoading: false});
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Modal 
          title={t("081D23C0-19D6-4939-A111-6562FF3B1C10", "Enter Confirmation Code")}
          visible={this.props.show} 
          closable={false}
          centered
          footer={[
            <Button 
              key="back" 
              onClick={this.resendCode}
              loading={this.state.isResendLoading}
              disabled={this.state.isResendLoading}
            >
              <Trans i18nKey="33AE50E4-1E85-4827-905F-66212C79812F">Resend Code</Trans>
            </Button>,
            <Button 
                key="submit" 
                type="primary" 
                onClick={this.recoverAccount}
                disabled={(this.state.confirmationCode.length < 4) || this.state.isLoading}
                loading={this.state.isLoading}
            >
              <Trans i18nKey="11595051-62F4-4C8C-B7DF-1B22A50ACFBE">Confirm</Trans>
            </Button>,            
          ]}>
          <p><Trans i18nKey="8C74AC75-F828-42C3-8F96-3A60C563560B">A notification with a 4-digit code has been sent to your RippleMaker. Please enter the code below.</Trans></p>

          {this.props.sent2FaRipple && 
            <p><Trans i18nKey="E3BF1F01-6B0F-4124-B2E1-CE5DF5D75103">Additionally, the code has been sent as a design to your "Mobile" section on the ripple maker, you can view it there as well. It may arrive faster.</Trans></p>          
          }
          
          <Input onChange={e => this.setState({confirmationCode: e.target.value})} />
          {this.state.isError && (
            <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert"><Trans i18nKey='6AD39293-7EFF-452A-A9D8-95FD004BC81F'>Wrong confirmation code, please try again.</Trans></div></div>
          )}

        </Modal>
      </>
    );
  }
};
export default withTranslation()(ConfirmCodeModal)