import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RolePermissionsPage.css"; // Import CSS file for styling

function RolePermissionsPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch data when username and password are provided
    if (username && password) {
      fetchRoles();
      fetchPermissions();
    }
  }, [username, password]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get("/api/roles", {
        auth: {
          username: username,
          password: password,
        },
        headers: {
          "Cache-Control": "no-cache", // Disable caching for this request
        },
      });
      // Sort roles by order
      const sortedRoles = response.data.data.sort((a, b) => a.order - b.order);
      setRoles(sortedRoles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.get("/api/permissions", {
        auth: {
          username: username,
          password: password,
        },
        headers: {
          "Cache-Control": "no-cache", // Disable caching for this request
        },
      });
      // Sort permissions by slug
      const sortedPermissions = response.data.data.sort((a, b) => {
        const slugOrder = [
          "admin",
          "user",
          "hierarchy",
          "organization",
          "location",
          "machine",
          "ripple",
          "channel",
          "webapp",
          "promotions",
          "notifications",
          "campaign",
          "shoutout",
        ];
        const aSlugIndex = slugOrder.findIndex((keyword) =>
          a.slug.startsWith(keyword)
        );
        const bSlugIndex = slugOrder.findIndex((keyword) =>
          b.slug.startsWith(keyword)
        );
        return aSlugIndex - bSlugIndex;
      });
      setPermissions(sortedPermissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleAuthChange = (e) => {
    if (e.target.name === "username") {
      setUsername(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handlePermissionToggle = async (roleId, permissionId, checked) => {
    // Save the current scroll position
    const { scrollY } = window;

    setLoading(true);
    try {
      await axios.post(
        `/api/roles/${roleId}/permissions`,
        { permissionId: permissionId, add: checked },
        {
          auth: {
            username: username,
            password: password,
          },
          headers: {
            "Cache-Control": "no-cache", // Disable caching for this request
          },
        }
      );
      // Update the roles state to reflect changes
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role.id === roleId
            ? {
                ...role,
                permissions: checked
                  ? [...role.permissions, { _id: permissionId }]
                  : role.permissions.filter(
                      (perm) => perm._id !== permissionId
                    ),
              }
            : role
        )
      );
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
    setLoading(false);
    // Restore the scroll position after state update
    window.scrollTo(0, scrollY);
  };

  const handleRefresh = () => {
    fetchRoles();
    fetchPermissions();
  };

  const exportToCSV = () => {
    const csvContent =
      `Key / Scope,Description,${roles.map((role) => role.name).join(",")}\n` +
      permissions
        .map(
          (permission) =>
            `${permission.slug},"${permission.name}",${roles
              .map((role) =>
                role.permissions.some((perm) => perm._id === permission.id)
                  ? "✔️"
                  : ""
              )
              .join(",")}`
        )
        .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "permissions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container">
      <h2>Roles and Permissions</h2>
      <div>
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={username}
          onChange={handleAuthChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleAuthChange}
        />
        <button onClick={handleRefresh}>Refresh</button>
        <button onClick={exportToCSV}>Export to CSV</button>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Key / Scope</th>
              <th>Description</th>
              {roles.map((role) => (
                <th key={role.id}>{role.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission) => (
              <tr key={permission.id}>
                <td>{permission.slug}</td>
                <td>{permission.name}</td>
                {roles.map((role) => (
                  <td key={`${permission.id}-${role.id}`}>
                    <input
                      type="checkbox"
                      checked={role.permissions.some(
                        (perm) => perm._id === permission.id
                      )}
                      onChange={(e) =>
                        handlePermissionToggle(
                          role.id,
                          permission.id,
                          e.target.checked
                        )
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default RolePermissionsPage;
